import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import PageErrorBoundary from './PageErrorBoundary';

const DashboardPage = lazy(() => import('screens/DashboardPage'));
const AdminsPage = lazy(() => import('screens/AdminsPage'));
const Companies = lazy(() => import('screens/Companies'));
const NewCompany = lazy(() => import('screens/NewCompany'));
const CompanyManagement = lazy(() => import('screens/CompanyManagement'));
const ClientsPage = lazy(() => import('screens/ClientsPage'));
const LogoutPage = lazy(() => import('screens/LogoutPage'));
const JobsPage = lazy(() => import('screens/JobsPage'));
const JobManagement = lazy(() => import('screens/JobManagement'));
const Applications = lazy(() => import('screens/Applications'));
const ApplicationEdit = lazy(() => import('screens/ApplicationEdit'));
const NotFoundPage = lazy(() => import('screens/NotFoundPage'));

function Router() {
  return (
    <BrowserRouter>
      <PageErrorBoundary>
        <Suspense fallback={<div>loading</div>}>
          <Switch>
            <Route exact path="/" component={DashboardPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/admins" component={AdminsPage} />
            <Route exact path="/companies" component={Companies} />
            <Route exact path="/companies/new" component={NewCompany} />
            <Route exact path="/companies/manage/:name" component={CompanyManagement} />
            <Route exact path="/clients" component={ClientsPage} />
            <Route exact path="/logout" component={LogoutPage} />
            <Route exact path="/jobs" component={JobsPage} />
            <Route exact path="/jobs/:id" component={JobManagement} />
            <Route exact path="/jobs/:id/applications" component={Applications} />
            <Route exact path="/jobs/:jobId/applications/:applicationId" component={ApplicationEdit} />
            <NotFoundPage />
          </Switch>
        </Suspense>
      </PageErrorBoundary>
    </BrowserRouter>
  );
}

export default Router;
