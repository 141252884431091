import React from 'react';
import Amplify from 'aws-amplify';
import {
  withAuthenticator,
  SignIn,
  ConfirmSignIn,
  RequireNewPassword,
  VerifyContact,
  ForgotPassword,
  TOTPSetup,
} from 'aws-amplify-react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import AWSConfig from 'utils/AWSConfig';
import Layout from 'shared/Layout';
import Router from 'utils/Router';

Amplify.configure(AWSConfig);

function App() {
  // window.LOG_LEVEL = 'DEBUG';
  return (
    <React.StrictMode>
      <ThemeProvider theme={createMuiTheme()}>
        <Layout>
          <Router />
        </Layout>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default withAuthenticator(App, false, [
  <SignIn />,
  <ConfirmSignIn />,
  <VerifyContact />,
  <ForgotPassword />,
  <RequireNewPassword />,
  <TOTPSetup />,
]);
